import React, { useState, useEffect } from "react";
//dependencies
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser"
//MaterialUI
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Notification from "../Notification";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: 'white',
    color:'black',
    padding:'30px',
    borderRadius:'15px',  
    boxShadow:'5px 5px 10px #222261'

  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'dark',
    width:'80px',
    height:'80px',
    border:'2px solid #4949a5'
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),   
    
  },
  texto: {

    backgroundColor: theme.palette.common.white,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding:'12px 0'
  },
  
}));

/**
 * Función para mostrar componente de login y hacer la validación de login con el backend.
 * @param {boolean} param0 
 * @returns redirecciona al index si es correcto el login, de lo contrario muestra mensaje de error.
 */
export default function SignIn({onLogin}) {
  const history = useNavigate();
  const {isLoginLoading, hasLoginError, login, isLogged} = useUser()
  const [errorMessage, setErrorMessage] = useState("");
  const initialFormData = Object.freeze({
    email: "",
    password: "",
  });
  const Notificacion = Notification();
  const [formData, updateFormData] = useState(initialFormData);

  /**
   * Verifica cambios en el formulario y agrega los valores al formData
   * @param {*} e 
   */
  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  /**
   * Valida si el login es correcto para redireccionar al index
   */
  useEffect(() => {
    if (isLogged) {
      history('/')
      onLogin && onLogin();
      window.location.reload();
    }
  }, [history, isLogged,onLogin]);

  /**
   * Valida si el login es incorrecto para enviar mensaje de error
   */
  useEffect(() => {
    if (hasLoginError) {
      alert("intente de nuevo por favor")
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
    }
  }, [hasLoginError])
  
  /**
   * Envia los datos del formulario a la función login que está en el hook useUser para consultar con el backend.
   * @param {*} e 
   */
  const handleLogin = (e) => {
    e.preventDefault();    
    login({
      username: formData.email,
      password: formData.password,
    })    
  };

  const classes = useStyles();

  return (
    <div className="">{isLoginLoading && <strong className="d-flex justify-content-center flex-column align-items-center">
      <div className="spinner-grow text-light " role="status">
      </div>
        <br />
        <br />
        <span class="sr-only d-inline">Cargando por favor espere...</span>
      </strong>}
      {!isLoginLoading &&
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}></Avatar>
          <Typography component="h1" variant="h5">
            Iniciar sesión
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              className={classes.texto} 
              
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo Electronico"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            />
            <TextField
            className={classes.texto}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleLogin}
            >
              Ingresar
            </Button>
            <Grid container>
              <Grid item xs>
                <span  variant="body2" className="cursorPointer" onClick={()=>{alert("Puedes contactarnos en www@www.com")}}>

                  Forgot password?
                </ span>
              </Grid>
              
            </Grid>
          </form>        
        </div>
      </Container>
       }
       { hasLoginError &&  <Notificacion message={errorMessage} />
       }
      
    </div>
  );
}
