/* eslint-disable array-callback-return */
import React from "react";
import VideosItemRow from "./videosItemRow";

import "../../styles/styles.css";
import "../../styles/conCate.css";

/**
 * Componente para organizar los videos por categoria
 * @param {*} categoria
 * @param {*} verVideo
 * @returns Elemento con los videos organizados por categoria
 */
const VideosCategoriaFila2 = ({ categoria, verVideo, ...props }) => {
  if (categoria) {
    const videosEnCategoria = props.videos && props.search(props.videos).filter((video) => (
      video.categorias.some((element) => categoria.categoria === element.categoria)
    ));

    return (
      <div>
        {videosEnCategoria.length > 0 && (
          <div className="col-12 contenedorXcateg">
            <h1 className="titleCateg" key={categoria.id}>{categoria.categoria}</h1>
            <div className="row">
              {videosEnCategoria.map((video, index) => (
                <VideosItemRow
                  key={index}
                  video={video}
                  histUsers={props.histUsers}
                  user={props.user}
                  verVideo={verVideo}
                >
                </VideosItemRow>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default VideosCategoriaFila2;
