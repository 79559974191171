import React, { useEffect, useState } from "react";
import Rating from '@mui/material/Rating';

import cx from "classnames";
import SliderContext from "../context/SliderContext";

import ShowDetailsButton from "./ShowDetailsButton"

//import Mark from './Mark'
import "./Item.scss";
//Components
import Button from "@material-ui/core/Button";

/**
 * Componente para mostrar el elemento en el slider
 * @param {Object} video 
 * @returns 
 */
const Item = ({ video,...props }) => {
  const [duracion, setDuracion] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const toggleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const changeDuration = (duration) => {
    let tiempo = duration.split(':');
    let new_duration = "";
    if (tiempo) {
      if (tiempo[0] !== "00") {
        new_duration = tiempo[0] + "h";
      }
      if (tiempo[1] !== "00") {
        new_duration = " " + new_duration + " " + tiempo[1] + "min";
      }
      /* if (tiempo[2] !=="00") {
        new_duration = " "+new_duration+" "+tiempo[2]+"s";
      } */
    }
    return new_duration.trim(); // trim() para eliminar cualquier espacio en blanco adicional
  };
  

  useEffect(() => {
    setDuracion(changeDuration(video.duration));

  }, [duracion, video.duration]);
  return (
    <SliderContext.Consumer>
      {({ onSelectSlide, currentSlide, elementRef, history }) => {
        const isActive = currentSlide && currentSlide.id === video.id;
        
        return (
          <div
            ref={elementRef}
            className={cx("item", {
              "item--open": isActive,
            })}
          >
            <div key={video.id} className="card  m-2 cursorPointer card-video">
              <Button onClick={() => props.verVideo(video.id)}>
                <img src={`https://videospruebasbackend.vcccolombia.com${video.featured_image}`} className="card-img-top cursorPointer imagen-container" alt="..." />
              </Button>
              {isActive}
              
              <div className="card-body pb-0 d-flex flex-column justify-content-between" key={video.id}>
                <h5 className="truncate-text" onClick={(event) => {event.stopPropagation(); onSelectSlide(video);}}>
                  {video.title_espanol}
                </h5>
                <div className="d-flex justify-content-between align-items-end" onClick={(event) => event.stopPropagation()}>
                  <div>
                    <p>{duracion}</p>
                  </div>
                  <div className="bg-secondary rounded-2 mb-2 pt-1" onClick={(event) => event.stopPropagation()}>
                    <Rating name="read-only" value={parseFloat(video.score)} precision={0.5} readOnly />
                  </div>
                </div>
              </div>
              <ShowDetailsButton onClick={() => onSelectSlide(video)}></ShowDetailsButton>
            </div>

           {/*  <h5>{video.title_espanol}</h5>
            <Button onClick={() => props.verVideo(video.id)}>
            <div className="imagen-container">
              <img
                src={"https://videospruebasbackend.vcccolombia.com" + video.featured_image}
                alt=""
              />
            </div>
            </Button>
            <ShowDetailsButton onClick={() => onSelectSlide(video)} />
            {isActive}
            
            <p>Duración: {duracion}</p>
            <p>aqui algoo</p>
            { video.tipe_of_video === 1 ? 
                (
                  <p >Video</p>
                ) : video.tipe_of_video === 2 ?
                (
                <p >Serie</p>
                ):
                <p >Caso</p>
            } */}
          </div>
        );
      }}
    </SliderContext.Consumer>
  );
};

export default Item;
