import React, { useEffect, useState } from "react";
//components
import { Image } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import '../slider/Item.scss'

const useStyles = makeStyles((theme) => ({
 
  item:{
    flex: '0 0 0%',
  margin: '0 0px',
  position: 'relative',
  padding:'0',
  },
  imagenContainer: {
    width: '360px', /* Ancho fijo deseado */
    height: '190px',/* Altura fija deseada */
    overflow: 'hidden', /* Recorta las imágenes que sean más grandes */
    border: '2px solid transparent',
  },
  imgClass: {
    width: '300px', /* Ancho fijo deseado */
    height:'200px',
  }
}));

/**
 * Componente para agregar un elemento a un row
 * @param {*} video
 * @returns Elemento con la información del video
 */
const VideosItemRow = ({ video,...props }) => {
  const classes = useStyles();  
  const [duracion, setDuracion] = useState([]);
  
  const changeDuration = (duration) => {
    let tiempo = duration.split(':');
    let new_duration = "";
    if (tiempo) {

      if (tiempo[0] !=="00") {
        new_duration = duration[0]+"h";
      }
      if (tiempo[1] !=="00") {
        new_duration = " "+new_duration+" "+tiempo[1]+"min";
      }
    }
    return new_duration;
  };
  useEffect(() => {
    setDuracion(changeDuration(video.duration));

  }, [duracion, video.duration]);
  
  return (
    <div className="col-12 col-sm-6  col-lg-3 mx-5 ">
      <h5 >{video.title_espanol}</h5>
      <div className="imagen-container ">
        <Button onClick={() =>props.verVideo(video.id)}>
         
          <img className="img w-100 imagenContainer "
                src={"https://videospruebasbackend.vcccolombia.com" + video.featured_image}
                alt=""
              />
        </Button>
      </div>
      <p>Duración: {duracion}</p>
    </div>
  );
};

export default VideosItemRow;
