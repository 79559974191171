import { useState, useRef, useEffect } from 'react';

const PADDINGS = 10; // Paddings ajustados para una mejor visualización
const CARD_WIDTH = 300; // Ancho de cada tarjeta

const useSliding = (countElements) => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [distance, setDistance] = useState(0);
  const [totalInViewport, setTotalInViewport] = useState(0);
  const [viewed, setViewed] = useState(0);

  useEffect(() => {
    const containerWidth = containerRef.current.clientWidth - PADDINGS;

    setContainerWidth(containerWidth);
    setTotalInViewport(Math.floor(containerWidth / CARD_WIDTH)); // Usamos floor en lugar de ceil
  }, []);

  const handlePrev = () => {
    if (viewed > 0) {
      setViewed(viewed - 1);
      setDistance(distance + CARD_WIDTH);
    }
  };

  const handleNext = () => {
    if (viewed < countElements - totalInViewport) {
      setViewed(viewed + 1);
      setDistance(distance - CARD_WIDTH);
    }
  };

  const slideProps = {
    style: {
      transform: `translate3d(${distance}px, 0, 0)`,
      transition: 'transform 0.4s ease' // Añadir transición para un deslizamiento suave
    }
  };

  const hasPrev = viewed > 0; // Corregido para evitar desplazamiento adicional
  const hasNext = viewed < countElements - totalInViewport; // Corregido para evitar desplazamiento adicional

  return { handlePrev, handleNext, slideProps, containerRef, hasPrev, hasNext };
};

export default useSliding;
