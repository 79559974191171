import React, { useEffect, useState } from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import { useParams } from "react-router-dom";

//dependencias
import * as VideoServer from "../../services/videoServer";
import * as SerieServer from "../../services/serie";
import * as TemporadaServer from "../../services/temporada";


import * as HistorialUserServer from "../../services/historialUser";
import * as commentaryServer from "../../services/commentary";
import { useModal } from "../../hooks/useModal";

//MaterialUI
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Container from "@material-ui/core/Container";
import StarIcon from "@mui/icons-material/Star";
import { makeStyles } from "@material-ui/core/styles";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Stack from '@mui/material/Stack';
import Typography from "@material-ui/core/Typography";
import Rating from '@mui/material/Rating';

//components
import "../../styles/styles.css";
import { Link } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  
}));
/**
 * Función para mostrar el detalle de un video y guardar la puntuación.
 * @returns Componente que muestra el detalle de un video junto con el iframe
 */
const SerieVDetail = () => {
  const history = useNavigate();
  const [show, handleShow, handleClose] = useModal(false);
  const [show2, handleShow2, handleClose2] = useModal(false);
  const [histUsers, setHistUsers] = useState();  

  const location = useLocation();
  const { id } = useParams();
  const [video, setVideo] = useState([]);
  const [serie, setSerie] = useState([]);
  const [temporada,setTemporada] = useState([]);
  const [duracion, setDuracion] = useState([]);
  const [uploadDate, setUploadDate] = useState(null);
  const [histUser, setHistUser] = useState(location.state);
  const [commentaries, setCommentaries] = useState([]);
  const [activeStar, setActiveStar] = useState(-1);
  const [cantidadVideosSerie, setCantidadVideosSerie] = useState(1);
  const totalStars = 5;
  const [videos, setVideos] = useState([]);
  const [temporadas, setTemporadas] = useState([]);
  const [idTemporada,setIdTemporada] = useState(0);
const arra = [1,2,3]
  
  useEffect(() => {
    const getSerie = async () => {
      const res = await SerieServer.getSerie(id);
      const serie = res;
      console.log(serie);

      setSerie({
        ...serie,
      });
    };
    getSerie();

  }, []);
    useEffect(() => {
    const getTemporadas = async () => {
    const res = await TemporadaServer.ListTemporadas()
    const temporada = res;
    setTemporada(temporada)
    setTemporadas(temporada)
    console.log(serie);
};
getTemporadas()

  }, []);
  

  useEffect(() => {
    const getVideos = async () => {
    const res = await VideoServer.ListVideos()
    const videos = res;
        setVideo(videos.videos)
    };
    getVideos()

  }, []);

  const getHistorialUsers = async () => {
    try {
      const res = await HistorialUserServer.ListHistorial();
      setHistUsers(res[0]);// aqui se le coloco el [0] porque si no me estaria llamando a todos los usuarios
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHistorialUsers();
  }, []);

  const entrarTemporada = (temporadaId)=>{
    history(`/seeTemporada/${temporadaId}`,{state:histUsers});
    setIdTemporada(temporadaId)
    console.log(idTemporada);
  }

  const entrarVideo = (videoId)=>{
    history(`/seeVideo/${videoId}`,{state:histUsers});
  }
  

   useEffect(() => {      
    if (histUser) {
      setActiveStar(histUser.user_score-1);
    } else {
      history("/login");      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 

  /**
   * Función para cambiar la calificación del video.
   * @param {int} index 
   */
  const handleClick = async (index) => {
    setActiveStar(index);
    let cumulative_score = video.cumulative_score;
    let number_votes = video.numberOfVotes;

    if (histUser.user_score) {
      cumulative_score = cumulative_score - histUser.user_score + index+1;
      const score_video = cumulative_score/number_votes;
      setHistUser({...histUser, user_score:index+1});
      await VideoServer.partialUpdateVideo(video.id, {'cumulative_score': cumulative_score, 'score': score_video})

    } else {
      number_votes +=1;
      cumulative_score = cumulative_score + index+1;
      const score_video = cumulative_score/number_votes;
      setHistUser({...histUser, user_score:index+1});
      await VideoServer.partialUpdateVideo(video.id, {'cumulative_score': cumulative_score,'numberOfVotes': number_votes,'score': score_video})
    }
    await HistorialUserServer.updateHistorialUser(histUser.id,{'user_score': index+1});

  };
  
  if (histUser) {
    console.log(temporada);

        return (
            <Container>
                
                <div className="">
                <div className="px-5  py-4" style={{ 
                    backgroundImage: `url(https://videospruebasbackend.vcccolombia.com/media/${serie.featured_image})`,
                    position: "relative",
                    color: "white",
                    height:'370px',
                    backgroundSize:'cover'
                }}>
                    {/* Fondo semi-transparente */}
                    <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.6)", 
                        boxShadow: 'inset 60px -90px 150px -10px black'// Ajusta el valor alpha para controlar la opacidad
                    }}></div>

                    {/* Contenido */}
                    <div className="info-serie" style={{
                        position: "relative", // Asegúrate de que el contenido esté por encima del fondo semi-transparente
                        zIndex: 1, // Asegúrate de que el contenido esté en la parte superior
                    }}>
                        <h1>{serie.serie}</h1>
                        <p className="w-50">{serie.description}</p>
                        
                        <div className="bg-light rounded-2 mb-2 pt-2  d-inline px-2" onClick={(event) => event.stopPropagation()}>
                          <Rating name="read-only" value={parseFloat(serie.quantity)} precision={0.5} readOnly />
                        </div>
                        <div className="d-flex justify-content-end">

                          <input type="button" value="Calificar serie" className="btn btn-outline-warning mt-3 py-2"/>
                </div>
                    </div>
                    {/* <p>⭐⭐⭐</p> */}
                </div>
                


                <div className="d-flex flex-wrap">
                {temporada.filter(temporada =>temporada.serie == id).map((temporada)=>{
                    return(
                        <div className=" border-2 card-video bg-dark border p-3 m-3 rounded card-width cursorPointer  " onClick={()=>{entrarTemporada(temporada.id)}}>
                            <h4 className="truncate-text">{temporada.temporada}</h4>
                            <p className="truncate-text">{temporada.description}</p>
                        </div>
                        )
                    })}
                </div>
                </div>

            </Container>
        );

  }
  return (
    <p style={{ fontSize: "25px" }}>Inicia sesión para ver todos los videos!</p>
  );
};

export default SerieVDetail;
