import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Prueba11() {
    const [categorias, setCategorias] = useState([]);

    useEffect(() => {
        async function getApis() {
            try {
                const response = await fetch("/categorias");
                const data = await response.json();
                console.log(data);
                setCategorias(data);
            } catch (err) {
                console.warn(err);
            }
        }
        getApis();
    }, []);

    return (
        <div>
            <h1>HOLA MUNDO</h1>
            {categorias.map((element, index) => (
                <p key={index}>{element.categoria}</p>
            ))}
        </div>
    );
}

export default Prueba11;
