import React from "react";

//components
import { Image } from "react-bootstrap";

//MaterialUI
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import '../../styles/styles.css'
const useStyles = makeStyles((theme) => ({
  paper: {
  },
  letter: {
  },
  imagenCarousel:{
    maxWidth:"100%",
    maxHeight:"700px", 
    objectFit:"cover",
  },
 
  
  "@media (min-width: 1200px)": {

    imagen:{
      minWidth: 820,
    },
    imagenCarousel:{
      width:1240,
      height:450,
      
    },
    row:{
      width:1240,
      maxheight:450,
    },
    
  },
}));
const changeDuration = (duration) => {
  let tiempo = duration.split(':');
  let new_duration = "";
  if (tiempo) {
    if (tiempo[0] !== "00") {
      new_duration = tiempo[0] + "h";
    }
    if (tiempo[1] !== "00") {
      new_duration = " " + new_duration + " " + tiempo[1] + "min";
    }
    /* if (tiempo[2] !=="00") {
      new_duration = " "+new_duration+" "+tiempo[2]+"s";
    } */
  }
  return new_duration.trim(); // trim() para eliminar cualquier espacio en blanco adicional
};

/**
 * Componente para mostrar en el carousel los videos con algunos campos.
 * @param {*} video
 * @returns Item de un video.
 */
const VideosItem = ({ video,...props } ) => {
  const classes = useStyles();

  
  return (
    <div id="contenedorItemVideo">
      <Button onClick={(e) => props.verVideo(video.id)}>
      <div className={"row  "+classes.row}>
        <div className={"col-md-8  col-12 imgPreview" +classes.imagen}>          
            <Image
              src={"https://videospruebasbackend.vcccolombia.com" + video.featured_image}
              className={classes.imagenCarousel }
            ></Image>
        </div>
        <div className={"col-md-4 col-12 " + classes.info}>
          <div>
          <div className="d-flex justify-content-between mt-3 text-capitalize mb-3">
            <p className="text-light h4">{changeDuration(video.duration)}</p>
            <Box sx={{'& > legend': { mt: 2 }}}>
              <div className="bg-secondary d-inline pt-3 px-1 rounded-2">
                <Rating name="read-only" value={parseFloat(video.score)} precision={0.5} readOnly />
              </div>
            </Box>
          </div>
          <h3 className="h3 text-light text-capitalize text-start">{video.title_espanol}</h3>
          <p className="text-light h5 font-weight-normal text-start text-capitalize">{video.description_esp}</p>
          </div>
          
        </div>
      </div>
      </Button>
    </div>
  );
};

export default VideosItem;
