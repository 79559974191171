import React, { useEffect, useState } from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import { useParams } from "react-router-dom";
import Rating from '@mui/material/Rating';

//dependencias
import * as VideoServer from "../../services/videoServer";
import * as SerieServer from "../../services/serie";
import * as TemporadaServer from "../../services/temporada";


import * as HistorialUserServer from "../../services/historialUser";
import * as commentaryServer from "../../services/commentary";
import { useModal } from "../../hooks/useModal";

//MaterialUI
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Container from "@material-ui/core/Container";
import StarIcon from "@mui/icons-material/Star";
import { makeStyles } from "@material-ui/core/styles";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Stack from '@mui/material/Stack';
import Typography from "@material-ui/core/Typography";

//components
import "../../styles/styles.css";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }
}));
/**
 * Función para mostrar el detalle de un video y guardar la puntuación.
 * @returns Componente que muestra el detalle de un video junto con el iframe
 */
const SerieDetail = () => {
  const history = useNavigate();
  const [show, handleShow, handleClose] = useModal(false);
  const [show2, handleShow2, handleClose2] = useModal(false);
  const [histUsers, setHistUsers] = useState();  

  const location = useLocation();
  const { id } = useParams();
  const [video, setVideo] = useState([]);
  const [serie, setSerie] = useState([]);
  const [temporada,setTemporada] = useState([]);
  const [duracion, setDuracion] = useState([]);
  const [uploadDate, setUploadDate] = useState(null);
  const [histUser, setHistUser] = useState(location.state);
  const [commentaries, setCommentaries] = useState([]);
  const [activeStar, setActiveStar] = useState(-1);
  const [cantidadVideosSerie, setCantidadVideosSerie] = useState(1);
  const totalStars = 5;

  
 /*  useEffect(() => {
    const getSeries = async (serieID) => {
      const res = await SerieServer.getSerie(2);
      const serie = res;
      console.log(serie);

      setSerie({
        ...serie,
      });
    };
    getSeries(video.serie);

  }, []); */
    useEffect(() => {
    const getTemporada = async () => {
    const res = await TemporadaServer.getTemporada(id)
    const temporada = res;
    console.log(temporada);
        setTemporada(temporada)
    };
    getTemporada(id)

  }, []);

  useEffect(() => {
    const getVideos = async () => {
    const res = await VideoServer.ListVideos()
    const videos = res;
    
    setVideo(videos.videos)
   
      };
    getVideos()

  }, []);
  const changeDuration = (duration) => {
    let tiempo = duration.split(':');
    let new_duration = "";
    if (tiempo) {
      if (tiempo[0] !== "00") {
        new_duration = tiempo[0] + "h";
      }
      if (tiempo[1] !== "00") {
        new_duration = " " + new_duration + " " + tiempo[1] + "min";
      }
      /* if (tiempo[2] !=="00") {
        new_duration = " "+new_duration+" "+tiempo[2]+"s";
      } */
    }
    return new_duration.trim(); // trim() para eliminar cualquier espacio en blanco adicional
  };
  
  
  const getHistorialUsers = async () => {
    try {
      const res = await HistorialUserServer.ListHistorial();
      console.log(res);
      setHistUsers(res[0]);// aqui se le coloco el [0] porque si no me estaria llamando a todos los usuarios
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHistorialUsers();
  }, []);



  const entrarVideo = (videoId)=>{
    history(`/seeVideo/${videoId}`,{state:histUsers});
  }
  

   useEffect(() => {      
    if (histUser) {
      setActiveStar(histUser.user_score-1);
    } else {
      history("/login");      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 

  /**
   * Función para cambiar la calificación del video.
   * @param {int} index 
   */
  const handleClick = async (index) => {
    setActiveStar(index);
    let cumulative_score = video.cumulative_score;
    let number_votes = video.numberOfVotes;

    if (histUser.user_score) {
      cumulative_score = cumulative_score - histUser.user_score + index+1;
      const score_video = cumulative_score/number_votes;
      setHistUser({...histUser, user_score:index+1});
      await VideoServer.partialUpdateVideo(video.id, {'cumulative_score': cumulative_score, 'score': score_video})

    } else {
      number_votes +=1;
      cumulative_score = cumulative_score + index+1;
      const score_video = cumulative_score/number_votes;
      setHistUser({...histUser, user_score:index+1});
      await VideoServer.partialUpdateVideo(video.id, {'cumulative_score': cumulative_score,'numberOfVotes': number_votes,'score': score_video})
    }
    await HistorialUserServer.updateHistorialUser(histUser.id,{'user_score': index+1});

  };
  
  if (histUser) {
    return (
    <Container>
      <div className="d-flex justify-content-end">
        <button  onClick={() => window.history.back()} className="btn btn-light" value="Volver">Volver</button>
      </div>
      <div>
        
        <div className="pb-5">
          <h2> {temporada.temporada}</h2>
          <p className="w-50">{temporada.description}</p>
          <div>

          <p className="border border-warning d-inline p-2 rounded text-warning">{video.filter((v) => v.temporada === temporada.id).length} Videos</p>
          {/* <button className="btn btn-warning mx-2">Nombre Serie </button> */}
          </div>
        </div>
        <div className="d-flex flex-wrap ">
        {video
  .filter((video) => video.temporada === temporada.id)
  .map((filteredVideo) => (
    <div key={filteredVideo.id} className="card bg-dark  m-2 cursorPointer card-video " onClick={() => entrarVideo(filteredVideo.id)}>
      <img src={`https://videospruebasbackend.vcccolombia.com${filteredVideo.featured_image}`} className="card-img-top cursorPointer  " alt="..." />
      <div className="card-body pb-0 d-flex  flex-column justify-content-between " key={filteredVideo.id}>
        <h6 className="truncate-text">
          {filteredVideo.title_espanol}
        </h6>
        <div className="d-flex justify-content-between ">
          <p>{changeDuration(filteredVideo.duration)}</p>
          <div>
          <Rating name="read-only" value={parseFloat(filteredVideo.score)} precision={0.5} readOnly />
          </div>
        </div>
      </div>
    </div>
  ))}

        </div>
      </div>
    </Container>
    );
  }
  return (
    <p style={{ fontSize: "25px" }}>Inicia sesión para ver todos los videos!</p>
  );
};

export default SerieDetail;
