import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//import { Link } from 'react-router-dom';
//dependencies
import * as SerieServer from "../services/serie";
import * as TemporadaServer from "../services/temporada";

import * as VideoServer from "../services/videoServer";
import * as HistorialUserServer from "../services/historialUser";

/* import SeriesList from "../components/videos/series"; */
//import VideoLoadingComponent from "../components/videos/videoLoading";
//components
import { Container } from "@material-ui/core";

/**
 * Carga todos los videos que sean 'Series' para el modulo Series de la aplicación
 */
function AppSeries() {
  

  const history = useNavigate();
  const [histUsers, setHistUsers] = useState();  
  const data = localStorage.getItem("user");
  const user = JSON.parse(data);
 /*  const VideoLoading = VideoLoadingComponent(SeriesList); */
  const [series, setSeries] = useState([]);
  const [videos, setVideos] = useState([]);
  const [idSerie, setIdSerie] = useState(0);
  
  const [temporadas, setTemporadas] = useState([]);
  const [idTemporada,setIdTemporada] = useState(0);
  const [appState, setAppState] = useState({
    loading: true,
    videos: null,
  });


  useEffect(() => {
    const getSeries = async () => {
      const res = await SerieServer.ListSeries();
      const series = res;
      setSeries(series);
    };
    getSeries();
  }, []);
  useEffect(() => {
    const getTemporadas = async () => {
      const res = await TemporadaServer.ListTemporadas();
      const temporadas = res;
      setTemporadas(temporadas);
    };
    getTemporadas();
  }, []);


  const getHistorialUsers = async () => {
    try {
      const res = await HistorialUserServer.ListHistorial();
      setHistUsers(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHistorialUsers();
  }, []);

 /**
   * Función para redireccionar al reproductor del video seleccionado, se crea un nuevo historial de usuario si es la primera vez en ver el video, de lo contrario se envía este historial como state
   * @param {*} id 
   */
  const entrarVideo = async (id)=>{
   
    const formData = new FormData();
    let histUser;
    let contador = 0;
    formData.append("usuario", user.id);
    formData.append("video", id);
    try {
      for (let index = 0; index < histUsers.length; index++) {
      const element = histUsers[index];
        if (element.usuario.id === user.id && element.video === id) {
          histUser= element;
          contador++;
        }
      }
      if(contador ===0) {
        const hu = await  HistorialUserServer.RegisterHistorialUser(formData);
        histUser = hu.data;
      }
    } catch (error) {
      console.log(error);
    }
    console.log(id);
    history(`/seeVideo/${id}`,{state:histUser});
  }



  const entrarTemporada = (temporadaId)=>{
    history(`/seeTemporada/${temporadaId}`,{state:histUsers});
    setIdTemporada(temporadaId)
    console.log(idTemporada);
  }
  const entrarSerie = (serieId)=>{
    history(`/seeSerie/${serieId}`,{state:histUsers});
    setIdSerie(serieId)
    console.log(serieId);
  }

  useEffect(() => {
    const getVideosSerie = async (serieId) => {

      const res = await VideoServer.ListVideosPorTemporada(serieId);
      const videos = res;
      setVideos(videos.videos);
      //setSeries(series);
    };
    console.log(idTemporada);
    getVideosSerie(idTemporada);
  }, []);
 


  return (
    <Container>
      <div className="d-flex justify-content-end">
        <button  onClick={() => window.history.back()} className="btn btn-light" value="Volver">Volver</button>
      </div>
         <h1 className="px-3"> Listado de Series</h1>
      <div className="d-flex flex-wrap">
          {series.map(serie =>{
            console.log(serie);
            return(
              <div className=" border rounded cursorPointer card-width  p-3 m-3">
                <div className=" " onClick={()=>{entrarSerie(serie.id)}}>
                <img src={'https://videospruebasbackend.vcccolombia.com/media/'+serie.featured_image} className="rounded mb-2" alt=""/>
                <h3 className=" text-capitalize truncate-text">{serie.serie}</h3>
                <p className=" truncate-text">{serie.description}</p>
                </div>
                <div className=" ">
                  {
                    temporadas.map((temporada)=>{
                      console.log(temporada);
                      if(serie.id === temporada.serie){
                        return(
                          <div>
                          
                          </div>
                        )
                      }
                    })
                  }
                </div>
                
              </div>
            )
          })}
      </div>
    </Container>
  );
}
export default AppSeries;
