import axiosInstance from "../axios";

const API_URL = "/palabrasclaves/";

/**
 * Función para consultar todas las categorias registradas en la base de datos.
 * @returns Data enviada desde el backend
 */
export const ListPalabrasClaves = async () => {
  const response = await axiosInstance.get(API_URL);
  if (response.status === 200) {
    return await response.data;
  }
};

export const RegisterPalabraClave = async (newPalabraClave) => {
  const response = await axiosInstance.post(API_URL, newPalabraClave);
  if (response.status === 200) {
    return await response.data;
  }
};

export const getPalabraClave = async (PalabraClaveID) => {
  const response = await axiosInstance.get(`${API_URL}${PalabraClaveID}`);
  if (response.status === 200) {
    return await response.data;
  }
};

export const updatePalabraClave = async(id,updPalabraClave) =>{
  const response = await axiosInstance.patch(API_URL+id+"/",updPalabraClave);
  if (response.status === 200) {
      return response.data;
  }
}