import React, { useEffect, useState } from "react";
import Select from 'react-select';

import "../../index.css";

/**
 * Componente con el buscador y el filtro por categoria
 * @param {*} props 
 * @returns Componente con el buscador y el filtro por categoria
 */
const SearchComponent = (props) => {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setCategories(props.categories);
  }, [props.categories]);

  if (categories) {
    return (
      <div className="wrapper mb-4 " >
        <div className="search-wrapper">
          
          <label htmlFor="search-form ">
            <p className="text-light pb-0 mb-1"> Buscas algo? </p>
            <input
              type="search"
              name="search-form"
              id="search-form"
              className="form-control bg-light  border-2 border-light mb-3 pe-5 "
              placeholder="Buscar por nombre"
              value={props.query}
              onChange={(e) => props.setQuery(e.target.value)}
            />
          </label>
          <div className="mt-3">
        
            <select
              onChange={(e) => props.setFilterParam(e.target.value)}
              className="form-select form-select-lg mb-3 py-2"
              aria-label="Filter Videos By Category"
            >
              <option value="All">Filtre por Categoria 🔠 </option>
              { categories.map((categ, index) => (
                <option key={index} value={categ.categoria}>
                  {categ.categoria}
                </option>
              ))}
            </select>
            <span className="focus"></span>
          </div>
        </div>        
      </div>
    );
  }
};
export default SearchComponent;